<template>
  <div class="modal fade " id="productItemDetailsModal" tabindex="-1" role="dialog"
       aria-labelledby="productItemDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Product Item Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th class="col-3">Product</th>
              <td class="col-9 text-capitalize">{{ currentProductItem?.product?.name ?? '' }}</td>
            </tr>
            <tr>
              <th class="col-3">Serial Number</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.serial_number }}</td>
            </tr>
            <tr>
              <th class="col-3">Model Number</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.model_number }}</td>
            </tr>
            <tr>
              <th class="col-3">SkU</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.sku }}</td>
            </tr>
            <tr>
              <th class="col-3">Bar Code</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.barcode }}</td>
            </tr>
            <tr>
              <th class="col-3">QR Code</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.qr_code }}</td>
            </tr>
            <tr>
              <th class="col-3">Manufacturing Code</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.manufacturing_code }}</td>
            </tr>
            <tr>
              <th class="col-3">Purchase Cost</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.purchase_cost }}</td>
            </tr>
            <tr>
              <th class="col-3">Condition</th>
              <td class="col-9 text-capitalize">{{ currentProductItem.condition }}</td>
            </tr>
            <tr>
              <th class="col-3">State</th>
              <td class="col-9 text-uppercase">{{ currentProductItem.state }}</td>
            </tr>
            <tr>
              <th class="col-3">Warehouse</th>
              <td class="col-9 text-capitalize">{{ currentProductItem?.warehouse?.name ?? '' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItemQuickViewModal",
  props: ['currentProductItem'],
}
</script>

<style scoped>

</style>