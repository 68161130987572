<template>
  <div class="modal fade " id="productItemHandoverModal" tabindex="-1" role="dialog"
       aria-labelledby="productItemHandoverModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Handover Technician</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h4 class="text-dark ">Technician</h4>
              <div class="form-group">
                <div class="controls">
                  <VueMultiselect v-model="selectedTechnician" class="multiselect-blue" :options="technicianOptions"
                                  :close-on-select="true" placeholder="Select Technician" label="name"
                                  track-by="name" :show-labels="false"/>
                </div>
              </div>
            </div>
            <!--            <div class="col-12">
                          <div class="form-group">
                            <div class="controls">

                                <textarea name="" id="" cols="30" rows="4" class="form-control"
                                          placeholder="Enter notes"></textarea>
                            </div>

                          </div>
                        </div>-->
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="createProductItemTrackings">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
export default {
  name: "ProductItemHandoverModal",
  mixins:[Loader,ShowToastMessage],
  props: ['currentProductItem'],
  data() {
    return {
      technicalValue: '',
      technical: [
        {
          name: 'Wahid S',
          language: 'Wahid S'
        },
        {
          name: 'Zubi J',
          language: 'Zubi J '
        },
        {
          name: 'Luis C ',
          language: 'Luis C J'
        },
        {
          name: 'Oshinne ',
          language: 'Oshinne '
        },
        {
          name: 'Sandip KC ',
          language: 'Sandip KC '
        },

      ],
      getTechniciansParams: {
        status: 1,
        with_relation:['user']
      },
      postProductItemTrackingData: {
        user_id: '',
        product_item_id: '',
        action: 4 // Advanced product is given to the technician
      },
      selectedTechnician: ''

    }
  },
  computed: {
    technicianOptions() {
      let activeTechnicians = this.$store.getters['appTechnicians/technicians'].map((technician) => {
        let technicianId = technician.id;

        let firstName = technician?.user?.first_name ?? '';
        let lastName = technician?.user?.last_name ?? '';
        let fullName = `${firstName} ${lastName}`;

        return {
          value: technicianId,
          name: fullName
        };
      });

      return [{
        value: '',
        name: `No One`
      }, ...activeTechnicians];
    },
  },
  watch: {
    selectedTechnician: {
      handler(currentValue) {
        this.postProductItemTrackingData.user_id = currentValue.value
      }
    },
    currentProductItem:{
      handler(currentValue){
        this.postProductItemTrackingData.product_item_id = currentValue.id
      }
    }
  },
  methods: {
    ...mapActions({
      getTechnicians: 'appTechnicians/getTechnicians',
      resetTechnician: 'appTechnicians/resetTechnician',
      postProductItemTracking: 'appProductItemTracking/postProductItemTracking',
    }),
    async getTechnicianList() {
      await this.getTechnicians(this.getTechniciansParams);
    },

    async createProductItemTrackings() {
      this.loader(true);
      await this.postProductItemTracking(this.postProductItemTrackingData).then((response) => {
        if (response.status === 201) {
          this.loader(false);
          document.querySelector('[data-target="#productItemHandoverModal"]').click();
          const toastObj = {message: 'Product Item Tracking successful.', type: 'success'};
          this.showToastMessage(toastObj);
          return;
        } else {
          this.loader(false);
        }
        this.errors.user_id = response?.errors?.user_id?.[0] ?? '';
        this.errors.product_item_id = response?.errors?.product_item_id?.[0] ?? '';
        this.errors.action = response?.errors?.action?.[0] ?? '';
        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
  },
  async mounted() {
    await this.getTechnicianList();
  },
  async beforeMount(){
    await this.resetTechnician();
  }

}
</script>

<style>

</style>
