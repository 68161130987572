<template>
  <div class="modal fade " id="productItemTrackingModal" tabindex="-1" role="dialog"
       aria-labelledby="productItemTrackingModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">History</h5>
          <button @click="resetDetails" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>User</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(productItemTracking) in productItemWithTrackingArray?.productItemTrackings" :key="productItemTracking">
              <td class="col-4">{{ userFullName(productItemTracking.user) }}</td>
              <td class="col-4">{{ productItemTracking.action }}</td>
              <td class="col-4">{{ productItemTracking.created_at }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button @click="resetDetails" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItemTrackingModal",
  props: ['productItemWithTrackingArray'],
  data() {
    return {
    }
  },
  watch: {

  },
  methods: {
    userFullName(userObj) {
      return `${userObj.first_name} ${userObj.last_name}`;
    },
    resetDetails() {
      Object.assign(this.$data, this.$options.data());
    }
  }
}
</script>

<style scoped>

</style>