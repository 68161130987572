<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Product Items/List</h4>

          <div>
            <router-link :to="can('product-item-create') ? {name:'appProductItemCreate'} : '#'">
              <span :title="can('product-item-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Serial Number</label>
                <input v-model="getProductItemParams.serial_number" class="form-control" type="text"
                       placeholder="">
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Model Number</label>
                <input v-model="getProductItemParams.model_number" class="form-control" type="text"
                       placeholder="">
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>SKU</label>
                <input v-model="getProductItemParams.sku" class="form-control" type="text"
                       placeholder="">
              </div>

            </div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect :allow-empty="false" v-model="selectedState" class="" :options="stateOptions"
                                :close-on-select="true" label="name"
                                track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Condition</label>
                <VueMultiselect :allow-empty="false" v-model="selectedCondition" class="" :options="conditionOptions"
                                :close-on-select="true" label="name"
                                track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Warehouse</label>
                <VueMultiselect :allow-empty="false" v-model="selectedWarehouse" class="" :options="warehouseOptions"
                                :close-on-select="true" label="name"
                                track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3 pt-2">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyProductItemFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'15%'}">Model No</th>
                    <th class="position-relative" :style="{width:'15%'}">Serial No</th>
                    <th class="position-relative" :style="{width:'10%'}">SKU</th>
                    <th class="position-relative" :style="{width:'15%'}">Purchase Cost</th>
                    <th class="position-relative" :style="{width:'10%'}">State</th>
                    <th class="position-relative" :style="{width:'20%'}">Warehouse</th>
                    <th class="position-relative" :style="{width:'20%'}">Condition</th>
                    <th class="position-relative" :style="{width:'10%'}">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(productItem) in productItems" :key="productItem.id">
                    <td>{{ productItem.model_number ?? 'Not Provided' }}</td>
                    <td>{{ productItem.serial_number ?? 'Not Provided' }}</td>
                    <td>{{ productItem.sku ?? 'Not Provided' }}</td>
                    <td>$ {{ parseFloat((productItem.purchase_cost)/100).toFixed(2) }}</td>
                    <td class="text-uppercase">{{ productItem.state  }}</td>
                    <td class="text-capitalize">{{ productItem?.warehouse?.name }}</td>
                    <td><span class="badge badge-sm"
                              :class="productItem.condition === 'New'? 'badge-light-success':
                         (productItem.condition === 'Used'? 'badge-light-warning' :'badge-light-danger')">
                      {{ productItem.condition }}</span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getProductItemForQuickView(productItem.id)" data-toggle="modal"
                               data-target="#productItemDetailsModal">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <router-link class="text-decoration-none"
                                     :to="can('product-item-view') ? {name:'appProductItemView',params:{id:productItem.id}} : '#'"
                                     :title="can('product-item-view') ? 'View' : 'View forbidden'"
                                     :style="{height:'18px'}">
                          <i class='bx bx-edit'></i>
                        </router-link>
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                          <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu">
                            <button class="dropdown-item"
                                    :disabled="!can('product-item-delete')"
                                    :title="can('product=item-delete') ? 'Delete' : 'Delete forbidden'"
                                    data-toggle="modal" data-target="#productItemDeleteAlertModal"
                                    @click="modelIdForDelete = productItem.id">
                              Delete
                            </button>
                            <button class="dropdown-item" data-toggle="modal" data-target="#productItemTrackingModal"
                                    @click="getSingleProductItem(productItem.id)"
                                    :disabled="!can('product-item-tracking-view-any')"
                                    :title="can('product-item-tracking-view-any') ? 'Delete' : 'Delete forbidden'">Tracking History
                            </button>
                            <button class="dropdown-item" data-toggle="modal" data-target="#productItemHandoverModal"
                                    @click="selectedProductItem = productItem">Handover Technician
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyProductItemFilter"/>
              </div>
            </div>
          </div>
        </div>
        <ProductItemQuickViewModal :current-product-item="singleProductItemObj"/>
        <ProductItemDeleteAlertModal model-name="productItem" :model-id="modelIdForDelete"
                                     modal-name="productItemDeleteAlertModal"
                                     @confirmModelDeletion='onConfirmModelDeletion'/>
        <ProductItemTrackingModal :product-item-with-tracking-array="productItem"/>
        <ProductItemHandoverModal :current-product-item="selectedProductItem"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ProductItemDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapGetters, mapActions} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
import ProductItemQuickViewModal from "@/views/backEnd/products/productItems/includes/ProductItemQuickViewModal";
import ProductItemTrackingModal from "@/views/backEnd/products/productItemTracking/ProductItemTrackingModal";
import ProductItemHandoverModal from "@/views/backEnd/products/productItems/includes/ProductItemHandoverModal";

export default {
  name: "ListProductItem",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ListPagination,
    ProductItemDeleteAlertModal,
    ProductItemQuickViewModal,
    ProductItemTrackingModal,
    ProductItemHandoverModal,

  },
  data() {
    return {
      selectedProductItem: {},
      modelIdForDelete: '',
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedCondition: {
        value: '',
        name: 'None'
      },
      selectedWarehouse: {
        value: '',
        name: 'None'
      },
      selectedState: {
        value: '',
        name: 'None'
      },
      getProductItemParams: {
        name: '',
        with_relation: ['product', 'warehouse', 'productItemTrackings', 'productItemTrackings.user'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: '',
        status: '',
        condition: '',
        state: '',
        warehouse_id: '',
        serial_number: '',
        model_number: '',
        sku: '',
      },
      getSettingsParams: {
        type: ['default', 'product_item'],
        key: ['default_pagination', 'default_state', 'product_item_condition'],
      },
      singleProductItemObj: {},
      productItem: {},
    }
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getProductItemParams.pagination = pagination.value;
    },
    selectedCondition() {
      this.getProductItemParams.condition = this.selectedCondition.value;
    },
    selectedWarehouse() {
      this.getProductItemParams.warehouse_id = this.selectedWarehouse.value;
    },
    selectedState() {
      this.getProductItemParams.state = this.selectedState.value;
    }
  },
  computed: {
    ...mapGetters({
      productItems: 'appProductItems/productItems',
      paginateLinks: 'appProductItems/paginateLinks',
    }),
    pagination() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
    conditionOptions() {
      let condition = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
      return [{value: '', name: 'None'}, ...condition];
    },
    stateOptions() {
      let states = this.$store.getters['appSettings/settingDefaultState'].value;
      return [{value: '', name: 'None'}, ...states];
    },
    warehouseOptions() {
      let warehouses = this.$store.getters['appWarehouses/warehouses'].map((warehouse) => {
        let id = warehouse.id, name = warehouse.name;
        return {value: id, name: name};
      })
      return [{value: '', name: 'None'}, ...warehouses]
    },
  },
  methods: {
    ...mapActions({
      getProductItems: 'appProductItems/getProductItems',
      getProductItem: 'appProductItems/getProductItem',
      getSettings: 'appSettings/getSettings',
      getWarehouses: 'appWarehouses/getWarehouses',
      deleteProductItemOnList: 'appProductItems/deleteProductItemOnList',
    }),
    async getProductItemForQuickView(productItemId) {
      this.singleProductItemObj = await this.productItems.find(({id}) => id === productItemId);
    },
    async deleteSingleProductItemOnLIst(id) {
      this.deleteProductItemOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Product Item deleted successfully",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      console.log(confirmModelDeletion);
      await this.deleteSingleProductItemOnLIst(confirmModelDeletion.modelId);
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async applyProductItemFilter(pageNumber) {
      this.getProductItemList.page = pageNumber;
      await this.getProductItemList(this.getProductItemParams);
    },

    async getProductItemList(productParams) {
      this.loader(true);
      await this.getProductItems(productParams)
          .then((response) => {
            if (response && response.message && response.status !== 200) {
              this.loader(false);
              this.showToastMessage(response);
            } else {
              this.loader(false);
            }
          });
    },

    async getSingleProductItem(id) {
      const params = {
        id: id,
        params: this.getProductItemParams,
      };
      await this.getProductItem(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.productItem = this.$store.getters['appProductItems/productItem'];
            }

            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }

          });
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList(this.getSettingsParams);
    await this.getWarehouses();
    await this.getProductItemList(this.getProductItemParams);
    await this.loader(false);
  }
}
</script>

<style scoped>

</style>